import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const HoverLink = ({ href, src, alt, titre, texte, hovered, onHover }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (event) => {
    if (isMobile) {
      if (!hovered) {
        event.preventDefault();
        onHover(true);
      } else {
        onHover(false);
      }
    }
  };

  return (
    <div className="grid-item">
      <NavLink
        to={href}
        onClick={handleClick}
        className={hovered ? "hovered" : ""}
      >
        <img src={src} alt={alt} className="grid-image" />
        <div className="grid-titre">{titre}</div>
        <div className="grid-texte">{texte}</div>
      </NavLink>
    </div>
  );
};

export default HoverLink;
