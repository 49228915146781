import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

const Contact = () => {
  const [surname, setSurname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phonenum, setPhonenum] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [errorMessage, setErrorMessage] = useState("");

  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (surname.trim() === "") {
      tempErrors["surname"] = "Nom est requis";
      isValid = false;
    }
    if (firstname.trim() === "") {
      tempErrors["firstname"] = "Prénom est requis";
      isValid = false;
    }
    if (phonenum.trim() === "") {
      tempErrors["phonenum"] = "Numéro de téléphone est requis";
      isValid = false;
    }
    if (mail.trim() === "") {
      tempErrors["mail"] = "Adresse e-mail est requise";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(mail)) {
      tempErrors["mail"] = "Adresse e-mail invalide";
      isValid = false;
    }
    if (message.trim() === "") {
      tempErrors["message"] = "Message est requis";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      try {
        const response = await fetch("/api/mail.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            surname,
            firstname,
            phonenum,
            mail,
            message,
          }),
        });

        if (response.ok) {
          setErrorMessage("Message envoyé! Merci.");
          // Réinitialiser les champs du formulaire
          setSurname("");
          setFirstname("");
          setPhonenum("");
          setMail("");
          setMessage("");
          setErrors({});
        } else {
          const result = await response.json();
          setErrorMessage(
            result.errors
              ? result.errors.join(", ")
              : "Une erreur est survenue. Veuillez réessayer plus tard."
          );
        }
      } catch (error) {
        setErrorMessage(
          "Une erreur est survenue. Veuillez réessayer plus tard."
        );
      }
    } else {
      setErrorMessage("Merci de remplir tous les champs.");
    }
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  return (
    <div>
      <Navigation />
      <div className="wrapper">
        <div className="contactContainer">
          <div className="contactBox">
            <form onSubmit={handleSubmit}>
              <p>ME CONTACTER</p>
              <input
                type="text"
                placeholder="Nom"
                id="surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                className={errors.surname ? "contact-erreur" : ""}
              />
              <input
                type="text"
                placeholder="Prénom"
                id="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                className={errors.firstname ? "contact-erreur" : ""}
              />
              <br />
              <input
                type="tel"
                placeholder="Numéro de téléphone"
                id="phonenum"
                value={phonenum}
                onChange={(e) => setPhonenum(e.target.value)}
                className={errors.phonenum ? "contact-erreur" : ""}
              />
              <input
                type="email"
                placeholder="Adresse e-mail"
                id="mail"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                className={errors.mail ? "contact-erreur" : ""}
              />
              <br />
              <textarea
                id="message"
                placeholder="Votre message ..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={errors.message ? "contact-erreur" : ""}
              />
              <br />
              <input type="submit" value="Envoyer" />
              {errorMessage && (
                <div className="message-erreur">{errorMessage}</div>
              )}
            </form>

            <div className="drop drop-1"></div>
            <div className="drop drop-2"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
