import React, { useRef, useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import { initializeSlider } from "../scripts/Slider";
import Footer from "../components/Footer";

const Divers = () => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (sliderRef.current) {
      initializeSlider(sliderRef.current.id, setActiveSlide);
    }
  }, [setActiveSlide]);

  const activeSlideContent = [
    {
      key: 1,
      imgSrc: "./data/img/slide-divers1.jpg",
      imgAlt: "Slide 1",
      titreSlide: "FACTEUR",
      descriptionSlide: "La Poste",
      experienceTitre: "La Poste",
      experienceSoustitre: "Facteur - Interim",
      experienceDate: "2022 / 2023",
      experience: (
        <p>
          - Wormhout, Esquelbecq, Zeggerscappel, Grand-Fort Philippe, Rosendaël,
          Dunkerque-Centre
          <br />- Tri du courrier général
          <br />- Préparation de la tournée
          <br />- Scan des colis / utilisation des applications sur Smartphone
          <br />- Distribution à pieds, à vélo et en voiture
          <br />- Distribution courrier / colis / lettres recommandées
          <br />- Vente de produits (timbres, enveloppes, ...)
          <br />- Mise en avant des services proposés par La Poste (entreprises,
          ...)
          <br />- Discretion vis-à-vis des clients
          <br />- Comptabilité (en cas de vente ou de taxes)
        </p>
      ),
      formationTitre: "Formations internes",
      formationDate: "",
      formationSoustitre: "",
      formation: (
        <p>
          - Sécurité sur la route et utilisation du VAE
          <br />- Fonctionnement général du tri, de la distribution et de
          l'utilisation des équipements
        </p>
      ),
      description: (
        <p>
          La nécessité de trouver un emploi m'a mené à travailler pour La Poste
          pendant un an et demi. Cette période restera pour moi un moment
          enrichissant humainement. Le rôle du facteur est avant tout un rôle de
          confiance vis-à-vis des usagers, mais aussi un travail de rigueur et
          de ponctualité. J'ai eu l'occasion de découvrir ici un métier riche de
          sens.
        </p>
      ),
    },
    {
      key: 2,
      imgSrc: "./data/img/slide-divers2.jpg",
      imgAlt: "Slide 2",
      titreSlide: "BRANCARDIER",
      descriptionSlide: "Polyclinique de Grande-Synthe",
      experienceTitre: "Polyclinique de Grande-Synthe",
      experienceSoustitre: "Brancardier",
      experienceDate: "2015 (Six mois)",
      experience: (
        <p>
          - Rotation sur trois services : Radiologie, Bloc Opératoire et Soins
          de Suite et Réadaptation (SRR)
          <br />- Guider ou transporter les patients en consultation
          <br />- Aider les patients et accompagner le kiné., psychomotricien et
          éducateurs sportifs dans les exercices de rééducation
          <br />- Utilisation du matériel de transport (lits, fauteuils
          roulants)
          <br />- Mise en place des techniques de levage
          <br />- Respect des Gestes et Postures
          <br />- Respect des règles d'hygiène, utilisation du matériel de
          protection
        </p>
      ),
      formationTitre: "Formations internes",
      formationDate: "",
      formationSoustitre: "",
      formation: (
        <p>
          - Formation liée à l'activation d'un "Plan Blanc"
          <br />- Risque incendie et utilisation du matériel d'extinction
          <br />- Formations concernant les risques liés aux patients contagieux
        </p>
      ),
      description: (
        <p>
          Mon parcours de bénévole à la Croix-Rouge française comme secouriste
          ainsi que mon intérêt pour le métier d'infirmier m'ont amené à
          découvrir le métier de brancardier à la Polyclinique de Grande-Synthe.{" "}
          <br />
          Une excellente expérience au contact des personnes. Le brancardier a
          un rôle de guide et s'assure du bon retour des patients en chambre ou
          en salle d'attente. C'est un métier de contact qui mène parfois à
          rassurer les patients, à dialoguer, et à travailler dans le respect du
          secret médical.
        </p>
      ),
    },
    {
      key: 3,
      imgSrc: "./data/img/slide-divers3.png",
      imgAlt: "Slide 3",
      titreSlide: "SERVEUR",
      descriptionSlide: "A l'Abordage",
      experienceTitre: "A l'Abordage",
      experienceSoustitre: "Serveur Bar - Remplacement",
      experienceDate: "2016 (Deux mois)",
      experience: (
        <p>
          - Ouverture / Fermeture
          <br />- Ménage
          <br />- Prise de commandes / service
          <br />- Encaissement
          <br />- Satisfaction client
        </p>
      ),
      formationTitre: "",
      formationDate: "",
      formationSoustitre: "",
      formation: "",
      description: (
        <p>
          Remplacement suite à une blessure, j'ai eu la chance de m'essayer au
          service en restauration (bar) pendant quelques semaines. <br />{" "}
          Expérience réussie !
        </p>
      ),
    },
    {
      key: 4,
      imgSrc: "./data/img/slide-divers4.jpg",
      imgAlt: "Slide 4",
      titreSlide: "CUEILLEUR",
      descriptionSlide: "Méteren (59)",
      experienceTitre: "Méteren (59)",
      experienceSoustitre: "Saisonnier",
      experienceDate: "Septembre 2016",
      experience: <p>- Cueillette des pommes et poires</p>,
      formationTitre: "",
      formationDate: "",
      formationSoustitre: "",
      formation: "",
      description: <p>Saison des pommes et poires sur Méteren.</p>,
    },
  ];

  return (
    <div>
      <Navigation />
      <div className="bandeau-large">
        <div className="bandeau-flexbox">
          {/* SLIDER */}
          <div
            className="slider-container"
            id="slider-container"
            ref={sliderRef}
          >
            <div className="slider">
              {activeSlideContent.map((slide) => (
                <div className="slideritem" key={slide.key}>
                  <img
                    src={slide.imgSrc}
                    alt={slide.imgAlt}
                    className="sliderimage"
                  />
                  <div className="slidertextecontainer">
                    <div className="slidertitre">
                      <h2>{slide.titreSlide}</h2>
                    </div>
                    <div className="slidertexte">{slide.descriptionSlide}</div>
                  </div>
                </div>
              ))}
              {/* Boutons slide */}
              <button id="slidernext">&gt;</button>
              <button id="sliderprev">&lt;</button>
            </div>
          </div>

          {/* DESCRIPTION POSTE */}
          <div className="description-container">
            <div className="description-item">
              <h3>Expérience professionnelle</h3>
              <i>Faites défiler les slides ... </i> <br />
              <br />
              <span className="description-titre">
                {activeSlideContent[activeSlide].experienceTitre}
              </span>
              {" - "} {activeSlideContent[activeSlide].experienceDate}
              <br />
              <div className="description-soustitre">
                {activeSlideContent[activeSlide].experienceSoustitre}
              </div>
              <br />
              <div className="description-texte">
                {activeSlideContent[activeSlide].experience}
              </div>
            </div>
            <div className="description-item">
              <h3>Formations</h3> <br />
              <span className="description-titre">
                {activeSlideContent[activeSlide].formationTitre}
              </span>
              {" - "} {activeSlideContent[activeSlide].formationDate}
              <br />
              <div className="description-soustitre">
                {activeSlideContent[activeSlide].formationSoustitre}
              </div>
              <br />
              <div className="description-texte">
                {activeSlideContent[activeSlide].formation}
              </div>
            </div>
            <div className="description-item">
              <div className="description-texte">
                {activeSlideContent[activeSlide].description}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Divers;
