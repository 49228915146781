import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="footer">
        <div class="footer-apropos-container">
          <h1 class="footer-logo">Jimmy M.</h1>

          <h2>A propos</h2>

          <div class="footer-apropos-texte">
            Ce site est destiné aux personnes souhaitant consulter le parcours
            de Jimmy M.
          </div>
        </div>

        <ul class="footer-nav">
          <li class="footer-nav-item">
            <h2 class="footer-nav-titre">Navigation</h2>

            <ul class="footer-nav-ul">
              <li>
                <a href="/autom">Automatisme</a>
              </li>

              <li>
                <a href="/fibre">Fibre Optique</a>
              </li>

              <li>
                <a href="/info">Informatique</a>
              </li>

              <li>
                <a href="/divers">Divers</a>
              </li>

              <li>
                <a href="/form">Formations</a>
              </li>

              <li>
                <a href="/extra">Activités extra.</a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="footer-maj">
          <p>Dernière mise à jour : 01 Août 2024</p>

          <div class="footer-lol">
            <span>
              Fabriqué avec <span class="footer-coeur">♥</span> depuis chez moi
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
