import React, { useRef, useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import { initializeSlider } from "../scripts/Slider";
import Footer from "../components/Footer";

const Extra = () => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (sliderRef.current) {
      initializeSlider(sliderRef.current.id, setActiveSlide);
    }
  }, [setActiveSlide]);

  const activeSlideContent = [
    {
      key: 1,
      imgSrc: "./data/img/slide-extra.jpg",
      imgAlt: "Slide 1",
      titreSlide: "ELECTRONIQUE",
      descriptionSlide: (
        <p>
          Arduino <br />
          ESP32 <br />
          Raspberry <br />
          Conception <br />
          DIY <br />
        </p>
      ),
      experienceTitre: "Electronique",
      experienceSoustitre: "Amateur",
      experienceDate: "",
      experience: (
        <p>
          - Conception de petits projets (Arduino, Raspberry, ESP32)
          <br />- Réparation électronique (PC, appareils, ...)
          <br />- Récupération de pièces / composants
          <br />- Soudure
          <br />- KiCAD (édition de schémas et PCB)
        </p>
      ),
      formationTitre: "",
      formationDate: "",
      formationSoustitre: "",
      formation: "",
      description: (
        <p>
          Dans la continuité de mon parcours de programmeur amateur et
          d'automaticien, j'ai naturellement un intérêt pour l'électronique et
          l'électricité en général. Amateur et autodidacte, j'apprends à
          comprendre les appareils domestiques, à les réparer et, si besoin, à
          les démonter pour récupérer des pièces pour d'éventuels projets
          personnels. Vive le <b>DIY</b> (Do It Yourself) !
        </p>
      ),
    },
    {
      key: 2,
      imgSrc: "./data/img/slide-extra2.jpg",
      imgAlt: "Slide 2",
      titreSlide: "MUSIQUE",
      descriptionSlide: (
        <p>
          Guitare
          <br />
          Clavier
          <br />
          MAO
        </p>
      ),
      experienceTitre: "Musicien",
      experienceSoustitre: "Amateur",
      experienceDate: "",
      experience: (
        <p>
          - Guitariste
          <br />- Clavier
          <br />- Musique Assistée par Ordinateur
          <br />- Enregistrement / mixage (<b>Cubase</b>)
        </p>
      ),
      formationTitre: "",
      formationDate: "",
      formationSoustitre: "",
      formation: "",
      description: (
        <p>
          Toujours en autodidacte, j'ai appris la guitare il y a maintenant plus
          de 15 ans. Touche-à-tout, je me suis lancé dans l'utilisation du
          clavier et des logiciels d'enregistrement. J'ai eu l'occasion de jouer
          plusieurs années dans un groupe dunkerquois, avec lequel nous avons
          donné quelques concerts.
        </p>
      ),
    },
    {
      key: 3,
      imgSrc: "./data/img/slide-extra3.jpg",
      imgAlt: "Slide 3",
      titreSlide: "TOUT EN SCENE",
      descriptionSlide: "Bénévole",
      experienceTitre: "Tout En Scène",
      experienceSoustitre: "Bénévole",
      experienceDate: "",
      experience: (
        <p>
          - Accueil du public et d'artistes
          <br />- Mise en place d'activités
          <br />- Logistique / fabrication de décors
          <br />- Installation électrique (luminaires, déco, ...)
          <br />- Installation de scènes (enceintes, lumières, table de mixage,
          ...)
          <br />- Bar / merchandising
        </p>
      ),
      formationTitre: "Formations internes",
      formationDate: "",
      formationSoustitre: "",
      formation: "",
      description: (
        <p>
          Tout En Scène est une association événementielle sur le Dunkerquois
          qui propose des lieux de vie à taille humaine : des
          concerts/festivals, événements culturels (parades, etc.) ou activités
          grand public. Mon parcours de musicien et de technicien en général m'a
          amené à rejoindre cette association vivante et humaine pendant
          quelques années.
        </p>
      ),
    },
    {
      key: 4,
      imgSrc: "./data/img/slide-extra4.png",
      imgAlt: "Slide 4",
      titreSlide: "CROIX-ROUGE",
      descriptionSlide: "Bénévole",
      experienceTitre: "Croix-Rouge française",
      experienceSoustitre: "Bénévole",
      experienceDate: "2011 - 2019",
      experience: (
        <p>
          - Secouriste
          <br />- Formateur au PSC1
          <br />- Maraudes
          <br />- Directeur local adjoint
          <br />- Directeur départemental adjoint chargé aux Missions de Secours
        </p>
      ),
      formationTitre: "Formations internes",
      formationDate: "",
      formationSoustitre: "",
      formation: (
        <p>
          - Premiers Secours en Equipe de niveau 1 et 2 (<b>PSE1</b>,{" "}
          <b>PSE2</b>)
          <br />- Pédagogie Initiale et Commune des Formateurs (<b>PICF</b>)
          <br />- Formateur aux Premiers Secours Civique de Niveau 1 (
          <b>PAE FPSC</b>)
          <br />- Initiateur à la Réduction des Risques (<b>IIRR</b>)
          <br />- Formation des Cadres Locaux de l'Urgence et du Secourisme (
          <b>CLUS</b>)
          <br />- Formation des Cadres Départementaux de l'Urgence et du
          Secourisme (<b>DLUS</b>)
          <br />- Tronc Commun des Acteurs de l'Urgence (<b>TCAU</b>)
          <br />- Module de Sensibilisation au Soutien Psychologique (
          <b>MSSP</b>)
          <br />- Opérateur Radio (<b>OPR</b>)
        </p>
      ),
      description: (
        <p>
          J'ai été bénévole pendant plusieurs années à la Croix-Rouge française
          (Dunkerque) en tant que secouriste. J'ai eu l'occasion de réaliser de
          nombreux postes de secours (événements culturels et sportifs,
          carnaval, etc.) et, par la même occasion, de suivre des formations
          internes pour mener à bien ces missions. Par la suite, j'ai pu devenir
          cadre local et départemental, mais aussi formateur pour le grand
          public.
        </p>
      ),
    },
  ];

  return (
    <div>
      <Navigation />
      <div className="bandeau-large">
        <div className="bandeau-flexbox">
          {/* SLIDER */}
          <div
            className="slider-container"
            id="slider-container"
            ref={sliderRef}
          >
            <div className="slider">
              {activeSlideContent.map((slide) => (
                <div className="slideritem" key={slide.key}>
                  <img
                    src={slide.imgSrc}
                    alt={slide.imgAlt}
                    className="sliderimage"
                  />
                  <div className="slidertextecontainer">
                    <div className="slidertitre">
                      <h2>{slide.titreSlide}</h2>
                    </div>
                    <div className="slidertexte">{slide.descriptionSlide}</div>
                  </div>
                </div>
              ))}
              {/* Boutons slide */}
              <button id="slidernext">&gt;</button>
              <button id="sliderprev">&lt;</button>
            </div>
          </div>

          {/* DESCRIPTION POSTE */}
          <div className="description-container">
            <div className="description-item">
              <h3>Activités extra-professionnelle</h3>
              <i>Faites défiler les slides ... </i> <br />
              <br />
              <span className="description-titre">
                {activeSlideContent[activeSlide].experienceTitre}
              </span>
              {" - "}
              {activeSlideContent[activeSlide].experienceDate}
              <br />
              <div className="description-soustitre">
                {activeSlideContent[activeSlide].experienceSoustitre}
              </div>
              <br />
              <div className="description-texte">
                {activeSlideContent[activeSlide].experience}
              </div>
            </div>
            <div className="description-item">
              <h3>Formations</h3> <br />
              <span className="description-titre">
                {activeSlideContent[activeSlide].formationTitre}
              </span>
              {" - "}
              {activeSlideContent[activeSlide].formationDate}
              <br />
              <div className="description-soustitre">
                {activeSlideContent[activeSlide].formationSoustitre}
              </div>
              <br />
              <div className="description-texte">
                {activeSlideContent[activeSlide].formation}
              </div>
            </div>
            <div className="description-item">
              <div className="description-texte">
                {activeSlideContent[activeSlide].description}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Extra;
