// fonction exportable, récupération d'ID du container
export function initializeSlider(containerId, setActiveSlide = () => {}) {
  const container = document.getElementById(containerId);
  if (!container) return;

  // récupération de la classe slideritem
  let items = container.querySelectorAll(" .slideritem");
  let active = 0;

  function loadShow() {
    // affichage de l'item actif
    items[active].style.transform = "none";
    items[active].style.zIndex = 50 + 1;
    items[active].style.filter = "none";
    items[active].style.opacity = 1;

    let stt = 0;

    // slide suivante à flouter
    for (let i = active + 1; i < items.length; i++) {
      stt++;
      items[i].style.transform = `translateX(${90 * stt}px) scale(${
        1 - 0.2 * stt
      }) perspective(20px) rotateY(-1deg)`;
      items[i].style.zIndex = 50 - stt;
      items[i].style.filter = "blur(4px)";
      items[i].style.opacity = stt > 2 ? 0 : 0.5;
    }

    // slide précédente à flouter
    stt = 0;
    for (let i = active - 1; i >= 0; i--) {
      stt++;
      items[i].style.transform = `translateX(${-90 * stt}px) scale(${
        1 - 0.2 * stt
      }) perspective(20px) rotateY(1deg)`;
      items[i].style.zIndex = 50 - stt;
      items[i].style.filter = "blur(4px)";
      items[i].style.opacity = stt > 2 ? 0 : 0.5;
    }

    // détermine la slide active pour les descriptions dynamique
    setActiveSlide(active);
  }

  loadShow();

  // récupération des id
  let next = container.querySelector("#slidernext");
  let prev = container.querySelector("#sliderprev");

  // bouton suivant
  if (next) {
    next.onclick = function () {
      active = active + 1 < items.length ? active + 1 : active;
      loadShow();
    };
  }

  // bouton précédent
  if (prev) {
    prev.onclick = function () {
      active = active - 1 >= 0 ? active - 1 : active;
      loadShow();
    };
  }
}
