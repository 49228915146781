import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
// import Bandeau from '../components/Bandeau';

const Erreur = () => {
  return (
    <div>
      <Navigation />
      <div className="bandeau-large">
        <div className="erreur">
          <h2>Oops ! Erreur 404 :-( </h2>
          <h3>Quelque chose ne fonctionne pas... </h3>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Erreur;
