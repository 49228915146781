import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Autom from "./pages/Autom";
import Fibre from "./pages/Fibre";
import Info from "./pages/Info";
import Divers from "./pages/Divers";
import Extra from "./pages/Extra";
import Contact from "./pages/Contact";
import Erreur from "./pages/Erreur";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="autom" element={<Autom />} />
        <Route path="fibre" element={<Fibre />} />
        <Route path="info" element={<Info />} />
        <Route path="divers" element={<Divers />} />
        <Route path="extra" element={<Extra />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<Erreur />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
