import React, { useRef, useEffect } from "react";
import Navigation from "../components/Navigation";
import { initializeSlider } from "../scripts/Slider";
import Footer from "../components/Footer";

const Fibre = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      initializeSlider(sliderRef.current.id);
    }
  });

  const activeSlideContent = [
    {
      key: 1,
      imgSrc: "./data/img/slide-fibre1.png",
      imgAlt: "Slide 1 Bureau d'Etude",
      titreSlide: "BUREAU D'ETUDE",
      descriptionSlide: (
        <p>
          Autocad Map
          <br />
          QJIS
          <br />
          Bureautique
        </p>
      ),
    },
    {
      key: 2,
      imgSrc: "./data/img/slide-fibre2.jpg",
      imgAlt: "Slide 2 Tirage de câbles",
      titreSlide: "TIRAGE DE CABLES",
      descriptionSlide: (
        <p>
          Aiguillage
          <br />
          Tirage de câbles
          <br />
          Installation des boîtiers
          <br />
          Epissure des fibres
        </p>
      ),
    },
    {
      key: 3,
      imgSrc: "./data/img/slide-fibre3.jpg",
      imgAlt: "Slide 3 Aérien",
      titreSlide: "CABLES AERIENS",
      descriptionSlide: (
        <p>
          Travail en hauteur
          <br />
          Nacelle
        </p>
      ),
    },
    {
      key: 4,
      imgSrc: "./data/img/slide-fibre4.jpg",
      imgAlt: "Slide 4 Armoires",
      titreSlide: "ARMOIRES FO",
      descriptionSlide: (
        <p>
          Installation D1 D2
          <br />
          Epissures
          <br />
          Réflectométrie, laser
          <br />
          Connexion des utilisateurs
        </p>
      ),
    },
    {
      key: 5,
      imgSrc: "./data/img/slide-fibre5.jpg",
      imgAlt: "Slide 5 Domicile",
      titreSlide: "DOMICILE",
      descriptionSlide: (
        <p>
          Installation FTTH D3
          <br />
          Box internet / TV
          <br />
          Satisfaction client
        </p>
      ),
    },
  ];

  return (
    <div>
      <Navigation />
      <div className="bandeau-large">
        <div className="bandeau-flexbox">
          {/* SLIDER */}
          <div
            className="slider-container"
            id="slider-container"
            ref={sliderRef}
          >
            <div className="slider">
              {activeSlideContent.map((slide) => (
                <div className="slideritem" key={slide.key}>
                  <img
                    src={slide.imgSrc}
                    alt={slide.imgAlt}
                    className="sliderimage"
                  />
                  <div className="slidertextecontainer">
                    <div className="slidertitre">
                      <h2>{slide.titreSlide}</h2>
                    </div>
                    <div className="slidertexte">{slide.descriptionSlide}</div>
                  </div>
                </div>
              ))}
              {/* Boutons slide */}
              <button id="slidernext">&gt;</button>
              <button id="sliderprev">&lt;</button>
            </div>
          </div>

          {/* DESCRIPTION POSTE */}
          <div className="description-container">
            <div className="description-item">
              <h3>Expérience professionnelle</h3>
              <br />
              <span className="description-titre">STTN</span> - 2019 / 2022
              <br />
              <div className="description-soustitre">
                Technicien Fibre Optique
              </div>
              <br />
              <div className="description-texte">
                - Installation et maintenance du réseau fibre optique FTTH
                (Orange & Bouygues) <b>Transport, D1 et D2</b>
                <br />- Installation et SAV à domicile. Mise en service des
                équipements internet/TV/téléphone (Orange & SFR) <b>D3</b>
                <br />- Aiguillage, tirage de câbles souterrains (chambres
                télécom) et aériens (poteaux ou façades)
                <br />- Installations horizontales et verticales (dans la rue ou
                en immeuble)
                <br />- Epissure des fibres (en PM, PA, PBo, DTIo)
                <br />- Réflectométrie
                <br />- Lecture de plans, compte-rendu de chantier,
                communication avec le bureau d'étude
                <br />- Formation <b>CACES Nacelle</b> & <b>H0B0</b>
              </div>
            </div>
            <div className="description-item">
              <h3>Formations</h3> <br />
              <span className="description-titre">TECHNICOM Lille</span> - 2019{" "}
              <br />
              <div className="description-soustitre">
                Formation Bureau d'Etude en fibre optique
              </div>
              <br />
              <div className="description-texte">
                - Analyser les besoins en vue d'un chantier de fibre optique
                <br />- Analyser les installations existantes, réseaux de ville
                et cadastre
                <br />- Traiter les retours terrain
                <br />- Créer des dossiers DOE, tracer et reporter des
                synoptiques sur plan (<b>AUTOCAD Map / QGIS</b>)
                <br />- Analyser et réaliser les plans pour la réalisation de
                tranchées, installation de chambres et poteaux
                <br />- Réaliser les plans et routage des câbles / boîtes
                <br />- Connaissance de l'ensemble du matériel d'infrastructure
                FO
                <br />- Traitement des données sur Excel
                <br />- Formation <b>AIPR opérateur</b>
              </div>
            </div>
            <div className="description-item">
              <h3>Informations</h3> <br />
              <div className="description-texte">
                J'ai eu la possibilité de suivre une formation de technicien en
                bureau d'études en fibre optique avec une opportunité de
                travailler dans le secteur de Dunkerque. L'entreprise n'ayant
                pas ouvert son bureau d'études, j'ai eu l'opportunité de
                travailler pour STTN en qualité de technicien de terrain. Mes
                connaissances en bureau d'études et les techniques développées
                sur le terrain ont fait de moi un technicien aguerri dans le
                domaine.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Fibre;
