import React, { useRef, useEffect } from "react";
import Navigation from "../components/Navigation";
import { initializeSlider } from "../scripts/Slider";
import Footer from "../components/Footer";

const Info = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      initializeSlider(sliderRef.current.id);
    }
  });

  const activeSlideContent = [
    {
      key: 1,
      imgSrc: "./data/img/slide-info1.jpg",
      imgAlt: "Slide 1 Web",
      titreSlide: "Dev WEB",
      descriptionSlide: (
        <p>
          Front-end
          <br />
          Back-end
        </p>
      ),
    },
    {
      key: 2,
      imgSrc: "./data/img/slide-info2.jpg",
      imgAlt: "Slide 2 App",
      titreSlide: "Dev logiciel",
      descriptionSlide: (
        <p>
          Programmation / Scripts
          <br />
          React Native
          <br />
          Unreal Engine
        </p>
      ),
    },
    {
      key: 3,
      imgSrc: "./data/img/slide-info3.jpg",
      imgAlt: "Slide 3 Divers",
      titreSlide: "Divers",
      descriptionSlide: (
        <p>
          Configuration PC <br />
          Réseau <br />
          Windows <br />
          Bureautique, ...
        </p>
      ),
    },
    {
      key: 4,
      imgSrc: "./data/img/slide-info4.png",
      imgAlt: "Slide 3 Jeux",
      titreSlide: "Jeux vidéos",
      descriptionSlide: (
        <p>
          Godot 4<br />
          GDScript <br />
          Unreal Engine <br />
          Mobile, ...
        </p>
      ),
    },
  ];

  return (
    <div>
      <Navigation />
      <div className="bandeau-large">
        <div className="bandeau-flexbox">
          {/* SLIDER */}
          <div
            className="slider-container"
            id="slider-container"
            ref={sliderRef}
          >
            <div className="slider">
              {activeSlideContent.map((slide) => (
                <div className="slideritem" key={slide.key}>
                  <img
                    src={slide.imgSrc}
                    alt={slide.imgAlt}
                    className="sliderimage"
                  />
                  <div className="slidertextecontainer">
                    <div className="slidertitre">
                      <h2>{slide.titreSlide}</h2>
                    </div>
                    <div className="slidertexte">{slide.descriptionSlide}</div>
                  </div>
                </div>
              ))}
              {/* Boutons slide */}
              <button id="slidernext">&gt;</button>
              <button id="sliderprev">&lt;</button>
            </div>
          </div>

          {/* DESCRIPTION POSTE */}
          <div className="description-container">
            <div className="description-item">
              <h3>Expérience personnelle</h3>
              <br />
              <span className="description-titre">Développement Web</span>
              <br />
              <div className="description-soustitre">Amateur</div>
              <br />
              <div className="description-texte">
                - Front-end : HTML, CSS, JavaScript & REACT
                <br />- Back-end : PHP, MySQL
                <br />- Utilisation de XAMPP, NodeJS & Visual Studio Code
                <br />- Connaissances en hébergement / FTP
              </div>
              <br />
              <br />
              <span className="description-titre">
                Développement Logiciel / Jeux Vidéos / Mobile
              </span>
              <br />
              <div className="description-soustitre">Amateur</div>
              <br />
              <div className="description-texte">
                - Connaissances en C/C++, C# (app console ou WPF)
                <br />- Connaissances en VB, DarkBasic
                <br />- Bases avec React Native
                <br />- Bases avec Godot 4 (GDscript)
                <br />- Bases avec Unreal Engine 5 (dont le Blueprint)
                <br />- Bases en conception 3D (Blender) & 2D (Photoshop)
                <br />- Macro Excel, ...
              </div>
              <br />
              <br />
              <span className="description-titre">Informatique générale</span>
              <br />
              <div className="description-soustitre">Amateur</div>
              <br />
              <div className="description-texte">
                - Montage et configuration PC
                <br />- Configuration Windows / Logiciels
                <br />- Réseau
                <br />- Bureautique, ...
              </div>
            </div>
            <div className="description-item">
              <h3>Informations</h3> <br />
              <div className="description-texte">
                Toujours dans la lignée de mon parcours en informatique,
                automatisme et électronique, je présente un intérêt pour le
                développement en général, et ce depuis que je suis collégien.
                Avide de connaissances, j'ai appris seul ou à travers le web à
                créer des sites internet, des logiciels ou encore des jeux vidéo
                en amateur. <br />
                Mon esprit curieux et touche-à-tout m'a conduit naturellement à
                manipuler les consoles, le réseau, la mémoire, à comprendre les
                différents mécanismes, à configurer des logiciels... Bref, la
                bidouille informatique !
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Info;
