import React, { useRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Navigation = () => {
  const menuRef = useRef(null);
  const navLinksRef = useRef(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    const handleMenuClick = () => {
      setIsMenuVisible((prev) => !prev);
    };

    const handleScroll = () => {
      if (isMenuVisible) {
        setIsMenuVisible(false);
      }
    };

    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        navLinksRef.current &&
        !navLinksRef.current.contains(event.target)
      ) {
        setIsMenuVisible(false);
      }
    };

    const menuButton = menuRef.current;
    if (menuButton) {
      menuButton.addEventListener("click", handleMenuClick);
    }

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      if (menuButton) {
        menuButton.removeEventListener("click", handleMenuClick);
      }
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuVisible]);

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <div className="navbar-logo-titre">
          <NavLink to="/" className="logo">
            <FontAwesomeIcon icon={faHome} />
          </NavLink>
          <div className="navbar-titre">Jimmy M.</div>
        </div>
        <div
          className={`nav-links ${isMenuVisible ? "mobile-menu" : ""}`}
          ref={navLinksRef}
        >
          <ul>
            <li>
              <NavLink to="/autom">AUTOMATISME</NavLink>
            </li>
            <li>
              <NavLink to="/fibre">FIBRE OPTIQUE</NavLink>
            </li>
            <li>
              <NavLink to="/info">INFORMATIQUE</NavLink>
            </li>
            <li>
              <NavLink to="/divers">DIVERS</NavLink>
            </li>
            <li>
              <NavLink to="/extra">ACTIVITES EXTRA.</NavLink>
            </li>
            <li>
              <NavLink to="/form">CV CLASSIQUE</NavLink>
            </li>
            <li>
              <NavLink to="/contact">ME CONTACTER</NavLink>
            </li>
          </ul>
        </div>
        <img
          src="./data/img/menu-btn.png"
          alt="Menu"
          className="menu-btn"
          ref={menuRef}
        />
      </nav>
    </div>
  );
};

export default Navigation;
