import React from "react";

const Bandeau = () => {
  return (
    <div className="bandeau">
        <div className="bandeau-item-photo">
          <img
            src="./data/img/photo.png"
            alt="Jimmy M."
            className="bandeau-photo"
          />
        </div>
        <div className="bandeau-item-texte">

            <span className="bandeau-nom">JIMMY M. 32 ans</span><br />
            <span className="bandeau-titre">
              &lt;&lt; La puissance de l'informatique &gt;&gt;
            </span>
          </div>
    </div>
  );
};

export default Bandeau;
