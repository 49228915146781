import React, { useRef, useEffect } from "react";
import Navigation from "../components/Navigation";
import { initializeSlider } from "../scripts/Slider";
import Footer from "../components/Footer";

const Autom = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      initializeSlider(sliderRef.current.id);
    }
  });

  const activeSlideContent = [
    {
      key: 1,
      imgSrc: "./data/img/slide-autom1.jpg",
      imgAlt: "Slide 1 Automatisme",
      titreSlide: "AUTOMATISME",
      descriptionSlide: (
        <p>
          SIEMENS - Step7 TIA Portal
          <br />
          SCHNEIDER - Unity Pro
        </p>
      ),
    },
    {
      key: 2,
      imgSrc: "./data/img/slide-autom2.png",
      imgAlt: "Slide 2 Supervision",
      titreSlide: "SUPERVISION",
      descriptionSlide: (
        <p>
          PCVue
          <br />
          Intouch
        </p>
      ),
    },
    {
      key: 3,
      imgSrc: "./data/img/slide-autom3.jpg",
      imgAlt: "Slide 3 Réseaux",
      titreSlide: "RESEAUX",
      descriptionSlide: (
        <p>
          Liaisons séries RS-232 -422 -485
          <br />
          ModBus
          <br />
          Profibus
          <br />
          ASi
          <br />
          ProfiNet
        </p>
      ),
    },
    {
      key: 4,
      imgSrc: "./data/img/slide-autom4.jpg",
      imgAlt: "Slide 4 Electrotechnique",
      titreSlide: "ELECTROTECHNIQUE",
      descriptionSlide: (
        <p>
          Compréhension de schémas
          <br />
          Câblage
          <br />
          Variateurs de vitesse
        </p>
      ),
    },
    {
      key: 5,
      imgSrc: "./data/img/slide-autom5.png",
      imgAlt: "Slide 5 Régulation",
      titreSlide: "REGULATION",
      descriptionSlide: (
        <p>
          Lecture de schéma instrumentation
          <br />
          Boucles PID
        </p>
      ),
    },
    {
      key: 6,
      imgSrc: "./data/img/slide-autom6.png",
      imgAlt: "Slide 6 Virtualisation",
      titreSlide: "VIRTUALISATION",
      descriptionSlide: (
        <p>
          VMWare
          <br />
          VirtualBox
        </p>
      ),
    },
  ];

  return (
    <div>
      <Navigation />
      <div className="bandeau-large">
        <div className="bandeau-flexbox">
          {/* SLIDER */}
          <div
            className="slider-container"
            id="slider-container"
            ref={sliderRef}
          >
            <div className="slider">
              {activeSlideContent.map((slide) => (
                <div className="slideritem" key={slide.key}>
                  <img
                    src={slide.imgSrc}
                    alt={slide.imgAlt}
                    className="sliderimage"
                  />
                  <div className="slidertextecontainer">
                    <div className="slidertitre">
                      <h2>{slide.titreSlide}</h2>
                    </div>
                    <div className="slidertexte">{slide.descriptionSlide}</div>
                  </div>
                </div>
              ))}
              {/* Boutons slide */}
              <button id="slidernext">&gt;</button>
              <button id="sliderprev">&lt;</button>
            </div>
          </div>

          {/* DESCRIPTION POSTE */}
          <div className="description-container">
            <div className="description-item">
              <h3>Expérience professionnelle</h3>
              <br />
              <span className="description-titre">
                Eiffage Energie Système
              </span>{" "}
              - 2018
              <br />
              <div className="description-soustitre">Automaticien</div>
              <br />
              <div className="description-texte">
                - Etude, conception et mise en service de projets neufs.
                <br />- Programmation d'automates et IHM avec mise en réseau.
                <br />- Modifications de ligne, revamping.
                <br />- Câblage, essais en atelier ou chez le tableautier.
                <br />- Réalisation de documents technique.
                <br />- Déplacement en France
              </div>
              <br />
              <br />
              <b>INEO</b> (Stage) - Octobre / Novembre 2017
              <br />
              <div className="description-soustitre">Automaticien</div>
              <br />
              <div className="description-texte">
                - Développement d'une centrale de traitement d'air : conception
                du programme automate et IHM avec technologie WAGO (Codesys)
                <br />- Modification de schémas électrique sous <b>AUTOCAD</b>
              </div>
            </div>
            <div className="description-item">
              <h3>Formations</h3> <br />
              <span className="description-titre">AFPA Dunkerque</span> - 2016 /
              2017 <br />
              <div className="description-soustitre">
                Technicien Supérieur en Automatisme et Informatique Industrielle
                (TSAII) eq. BTS{" "}
              </div>
              <br />
              <div className="description-texte">
                - Automatisme <b>Schneider</b> / <b>Siemens</b>
                <br />- Programmation Grafcet, Ladder, List, Structuré
                <br />- Réseaux (Profinet, Profibus, Modbus, ASI, liaisons
                série)
                <br />- IHM et Supervision (Vigeo Designer, PCVue, WinCC)
                <br />- Electrotechnique - Variateurs - Régulation
                <br />- Pneumatique
                <br />- Programmation informatique (C# SQL)
                <br />- VMWare
                <br />- Habilitation <b>BR</b> et <b>ANFAS N1</b>
              </div>
              <br />
              <br />
              <span className="description-titre">AFPI Dunkerque</span> - 2023 /
              Arrêté <br />
              <div className="description-soustitre">
                Technicien Supérieur en Maintenance Industrielle (TSMI) eq. BTS{" "}
              </div>
              <br />
              <div className="description-texte">
                - Automatisme <b>Schneider</b>
                <br />- Mécanique (lecture et dessin de coupes, vues)
                <br />- Pneumatique
                <br />- Hydraulique
                <br />- Electrotechnique
                <br />- Usinage
                <br />- Habilitation <b>BR</b> et <b>ANFAS N1</b>
              </div>
            </div>
            <div className="description-item">
              <h3>Informations</h3> <br />
              <div className="description-texte">
                J'ai suivi une formation d'Automaticien très enrichissante à
                l'AFPA de Dunkerque dans le but de travailler dans un domaine
                liant à la fois l'informatique, la technologie industrielle et
                l'électrotechnique. Mon stage chez INEO m'a permis d'appréhender
                le métier et de réaliser mon premier projet en totale autonomie,
                de la conception du programme (d'après une Analyse
                Fonctionnelle) jusqu'à la mise en service. J'ai eu l'opportunité
                de travailler à nouveau en bureau d'études chez Eiffage où j'ai
                développé davantage mes connaissances sur des projets variés :
                du revamping au projet neuf en passant par la modification de
                projets existants.
                <br />
                Dans l'ensemble, j'ai pu programmer des automates, les câbler,
                réaliser des essais, concevoir des IHM et des supervisions.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Autom;
