import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import Bandeau from "../components/Bandeau";
import HoverLink from "../scripts/HoverLink";
import Footer from "../components/Footer";

const Home = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  const links = [
    {
      href: "/autom",
      src: "./data/img/item-autom.jpg",
      alt: "Automatisme",
      titre: "AUTOMATISME",
      texte: (
        <p>
          Automaticien avec une expérience orientée vers le bureau d'études.{" "}
          <br />
          <br />
          Programmation d'automates, IHM et supervision. <br />
          <br />
          Câblage, électrotechnique et informatique industrielle.
        </p>
      ),
    },
    {
      href: "/fibre",
      src: "./data/img/item-fibre.jpg",
      alt: "Fibre Optique",
      titre: "FIBRE OPTIQUE",
      texte: (
        <p>
          Formé au bureau d'études, avec une expérience de technicien de
          terrain. <br />
          <br />
          Installation D1, D2 et D3.
        </p>
      ),
    },
    {
      href: "/info",
      src: "./data/img/item-info.jpg",
      alt: "Informatique",
      titre: "INFORMATIQUE",
      texte: (
        <p>
          Passionné et autodidacte, je présente un intérêt pour le développement
          web et logiciel, ainsi que pour l'informatique en général.
        </p>
      ),
    },
    {
      href: "/divers",
      src: "./data/img/item-divers.png",
      alt: "Divers",
      titre: "DIVERS",
      texte: (
        <p>
          A la fois facteur, brancardier ou encore cueilleur de pommes: mon
          parcours professionnel est riche et varié.
        </p>
      ),
    },
    {
      href: "/extra",
      src: "./data/img/item-extra.jpg",
      alt: "Activités extra.",
      titre: "ACTIVITES EXTRA",
      texte: (
        <p>
          L'électronique, la musique, le bénévolat : retrouvez l'ensemble de mes
          activités extra-professionnelles.
        </p>
      ),
    },
    {
      href: "/cv",
      src: "./data/img/item-form.jpg",
      alt: "CV Classique",
      titre: "CV CLASSIQUE",
      texte: <p>Consultez mon parcours de façon classique et chronologique.</p>,
    },
  ];

  const handleHover = (index) => {
    if (hoveredIndex === null || hoveredIndex !== index) {
      setHoveredIndex(index);
      setShowMessage(true);
    } else {
      setHoveredIndex(null);
      setShowMessage(false);
    }
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => setShowMessage(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  return (
    <div>
      <Navigation />
      <Bandeau />
      {/* Ensemble des menus */}
      <div className="grid-container">
        {links.map((link, index) => (
          <HoverLink
            key={index}
            href={link.href}
            src={link.src}
            alt={link.alt}
            titre={link.titre}
            texte={link.texte}
            hovered={hoveredIndex === index}
            onHover={() => handleHover(index)}
          />
        ))}
      </div>
      {showMessage && (
        <div className="hover-message">
          Cliquez une seconde fois pour accéder à la page
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Home;
